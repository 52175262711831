<!--
 * @Author: your name
 * @Date: 2021-12-07 15:40:36
 * @LastEditTime: 2023-02-10 15:56:24
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 加入我们
 * @FilePath: \new-website-1123\src\views\service\joinus\index.vue
-->
<template>
  <div class="service-joinus">
    <!--banner-->
    <div
      class="contactus-us"
      :style="{
        backgroundImage: 'url(' + banner + ')',
        'background-size': 'cover',
      }"
    >
      <div class="contactus-us-view">
        <div class="contactus-us-infor">
          <h2>向光而生 热爱无限</h2>
          <h4>加入我们，一起用科技领航未来!</h4>
        </div>
      </div>
    </div>

    <!--人才理念-->
    <div class="flexbox-join concept-connect ">
      <div class="flexbox-item-join">
        <w-title
          model="人才"
          modelkey="理念"
          paragraph="人才是企业发展的源源动力。上太科技格外重视人才的吸引与培养。
公司建立完善的人才内训计划与晋升机制，为优秀人才创造良好的
成长平台，帮助员工在工作生活中充分发挥自身价值。"
          direction="left"
        :isDetails="false"
        ></w-title>
      </div>
      <div class="flexbox-item-img">
        <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/concept-pic.jpg" />
      </div>
      <div class="mobile-img">
      <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/concept-pic.jpg" />
    </div>
    </div>

    <!-- 员工福利 -->
    <p-advantage
      class=""
      :title="{
        text: '员工',
        color: '福利',
      }"
      :dataList="advantageList"
      v-if="advantageList.length > 0"
    >
      <template v-slot:paragraph>
        每逢中秋、端午、春节等节日，公司均为员工精心挑选节日礼品，并举行团建活动，增加员工对企业的归属感、同事一家亲的认同感。
      </template>
    </p-advantage>

    <!--加入我们-->
    <div class="recruit-connect  ">
      <w-title
        model="加入"
        modelkey="我们"
        paragraph="欢迎投递简历至：stkjadmin@stkjiot.com。相信我们可以为你带来一些惊喜！"
        :isDetails="false"
        direction="center"
      ></w-title>
      <div class="search" >
        <el-cascader clearable  placeholder="请选择职位类型" :props="selectProps" v-model="searchList" :options="recTree" @change="searchChange" />
      <el-button type="primary" icon="el-icon-search" @click="searchClick(searchInfo)"></el-button>
      </div>

      <ul  class="recruit-connect-ul">
        <li v-for="(item, index) in recruit" :key="index"
          @click="goJoinDetails(item)"
        >
            <h2>{{ item.type }}</h2>
            <div class="base">
              <span>{{ item.provinceId }}/{{ item.cityId }}</span>
              <span class="split">|</span>
              <span>{{ item.name }}</span>
              <span class="split">|</span>
              <span>{{ getExperience(item.experience) }}</span>
            </div>
            <div class="info">
              <div class="description">{{ item.description }}</div>
              <div class="requirement">{{ item.requirement }}</div>
            </div>
            <img
                class="ml10"
                width="40"
                src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png"
                alt="上太科技"
              />
        </li>
      </ul>
      <div v-show="!recruit.length" class="default">该职位类型下暂无职位</div>
      <pagination @pagination="handleCurrentChange"  :pageNum="page.pageNum" class="page" :total="total"></pagination>
    </div>

  </div>
</template>

<script>
import {getRecruitListApi,getRecruitTypeApi} from '@/api/index.js'
import wTitle from "@/components/sTitle/viewtitle";
import pAdvantage from "../../programme/components/swiper.vue";
import pagination from "@/components/pagination/index.vue";
import {getBannerList}from '@/utils/getBanner.js'
export default {
  name: "service-joinus",
  components: {
    wTitle,
    pAdvantage,
    pagination
  },
  data() {
    return {
      page:{
        pageNum:1,
        pageSize:10
       },
      total:0,
      selectProps : {
        checkStrictly: false,
        children: 'sonList',
        label: 'name',
        value: 'name'
        },
      recTree:[],
      searchInfo:'',
      searchList:[],
      banner: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/joinus-pic.jpg",
      changenamber: 1,
      recruit: [ ],
      advantageList: [
        // {
        //   key: 1,
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/welfare1.jpg",
        // },
        // {
        //   key: 2,
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/welfare2.jpg",
        // },
        // {
        //   key: 3,
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/welfare3.jpg",
        // },
        // {
        //   key: 4,
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/welfare4.jpg",
        // },
        // {
        //   key: 5,
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/welfare5.jpg",
        // },
      ],
      options: [{
          value: '智慧农业产品经理1',
          label: '智慧农业产品经理1'
        }, {
          value: '智慧农业产品经理2',
          label: '智慧农业产品经理3'
        }, {
          value: '智慧农业产品经理3',
          label: '智慧农业产品经理3'
        }, {
          value: '智慧农业产品经理4',
          label: '智慧农业产品经理4'
        }, {
          value: '智慧农业产品经理5',
          label: '智慧农业产品经理5'
        }],
        value: '',
        tempList:[],
        searchShow:false,
    };
  },
  created() {
    this.getJoinList()
    this.getRecruitTree()
    this.getBanner()
  },
  computed: {
    currentDevice() {
      return this.deviceList.filter((item) => item.key == this.deviceActive)[0];
    },
  },
  methods: {
    getBanner(){
      getBannerList('YGFL001Ban05').then(res=>{
        this.advantageList = res
      })
    },

    dataListopen(index) {
      var self = this;
      self.changenamber = index;
    },
    getExperience(val){
      switch (true) {
        case val == 1:
         return '不限';
        case val == 2:
          return '1-3年';
        case val == 3:
          return '3-5年';
        case val == 4:
          return '5年以上';
        default:'不限'
          break;
      }

    },
    goJoinDetails(item){

      this.$router.push({
        path:'/support/joinDdetails',
        query:{
          item
        }
      }).then(res=>{
        this.searchShow = true
      })
    },
      /**
     * @description: 分页
     */
     handleCurrentChange(val){

      this.page.pageNum = Number(val);
      this.getJoinList()
    },
    getJoinList(){
      getRecruitListApi({...this.page,type:this.searchInfo,status : 1}).then(res=>{
        this.recruit = res.data.data.list
        this.total = res.data.data.total;
      })
    },
    getRecruitTree(){
      getRecruitTypeApi().then(res=>{
        this.recTree = res.data.data;
      })
    },
    searchChange(val){
      this.searchList = !val?[]:val
      this.searchInfo = val.join('-');
    },
    searchClick(val){
      this.page.pageNum = 1;
      this.getJoinList()
    }
  },
};
</script>

<style lang="scss" scoped>
.flexbox-join {
  width: 100%;
  height: 460px;
  margin-top: 253px;
  display: flex;
  align-items: center;
  background-color: #1d1d1d;
  position: relative;
  .flexbox-item-join {
    height: 100%;
    padding-left: 208px;
    padding-top: 99px;
  }
  .flexbox-item-img {
    position: absolute;
    right: 10.83vw;
    bottom: 2.76vw;
    width: 36.4vw;
    height: 26vw;
    margin-left: 2vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; //
  object-position: center;

    }
  }

}
.mobile-img{
    display:none;
  }
.contactus-us {
  height: 480px;

  .contactus-us-view {
    width: 1200px;
    padding-top: 196px;
    margin: 0 auto;
    .contactus-us-infor {
      color: $color-text;
      text-align: center;
      h2 {
        font-size: 72px;
        margin-bottom: 42px;
      }
      h4 {
        font-size: 28px;
        margin-bottom: 0;
        font-weight: normal;
      }
    }
  }
}

.concept-connect {
  width: 100%;
  .flexbox-item {
    padding: 88px;
  }
}

.recruit-connect {
  padding: 110px 0;
  .search {
    width: 100%;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
  .recruit-connect-ul {
    padding: 0 208px;
    margin: 0 auto;
    color: $color-text;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    margin-top: 80px;
    li {
      width: 720px;
      min-width: 720px;
      height: 199px;
      padding: 24px;
      margin-right: 20px;
      background-color: #1d1d1d;
      cursor: pointer;
      color: #FFFFFF;
      margin-bottom: 32px;
      position: relative;
      .ml10{
          display: none;
          position: absolute;
          right: 40px;
          bottom: 24px;

        }
      &:hover {
        background-color: #172820;
        .ml10{
          display: block;
          transform: rotate(360deg);
        }
      }
      h2 {
        font-size: 24px;
        margin-bottom: 24px;
        font-weight: normal;

      }
      .base {
        font-size: 16px;
        margin-bottom: 16px;
        .split {
          margin: 0 10px;
        }
      }
      .info {
        width: 100%;
        margin-right: 162px;
        color: #B3B3B3;
        font-size: 14px;
        .description {
          margin-bottom: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .requirement {
          height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          display: -webkit-box;
        }
      }
    }
  }
  .default {
    text-align: center;
    color: #fff;
    font-size: 20px;
  }
  ::v-deep .el-input--suffix .el-input__inner{
  border-radius: 0;
  background-color: #161616;
  border: 1px solid #707070;
  color: #fff;
}
.page {
  text-align: center;
}
::v-deep .el-input--suffix .el-input__inner::placeholder{
  color: #d0caca;
}
::v-deep  .el-input--suffix .el-input__inner {
  width: 28.125vw;
  min-width: 200px;
}


}


.el-select-dropdown__item.selected {
    background-color: #1dbf73;
    color: #fff;
}


::v-deep .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
  background-color: #a7a1a1;
  color: #fff;
}

.el-button:focus,
    .el-button:hover {
      border-color: #1dbf73  !important;
      background-color: #1dbf73  !important;
    }

    .el-button--primary {
      --el-button-bg-color: #1dbf73  !important;
      --el-button-border-color: #1dbf73  !important;
      color: #fff;
    }

@media only screen and (max-width: $mobile-width) {
  .contactus-us {
    height: 5.91rem;
    .contactus-us-view {
      width: 100%;
      padding-top: 2.4rem;
      .contactus-us-infor {
        h2 {
          font-size: 0.42rem;
          margin-bottom: 0.36rem;
        }
        h4 {
          font-size: 0.24rem;
        }
      }
    }
  }

  .concept-connect {
    display: block;
    padding: 0;
    width: 86.4%;
    height: auto;
    margin: 0 auto;
    margin-top: 0.91rem;
    .flexbox-item {
      padding: 0;
      .w-title-box.left {
        text-align: center;
      }
      img {
        max-width: 100%;
      }
    }
    .flexbox-item-img {
      display: none;

    }

  }

  .recruit-connect {
    width: 86.4%;
    margin: 0 auto;
    padding-top: 1.3rem;
    padding-bottom: 0.91rem;

    .recruit-connect-ul {
      margin-top: 0.91rem;
      padding: 0;
      width: 100%;
      li {
        min-height: 0.8rem;
      width: 100%;
      min-width: 100% !important;

        padding: 0 0.27rem;
        margin-bottom: 0.27rem;
        &.active .recruit-connect-view {
          padding: 0.36rem 0;

        }
        .recruit-connect-title {
          line-height: 0.8rem;
          h2 {
            margin-right: 0.27rem;
            font-size: 0.27rem;
          }
          .info {
            width: 100%;
          }
          span.flexbox-item {
            font-size: 0.2rem;
          }
          .openicon {
            width: 0.29rem;
            height: 0.29rem;
            line-height: 0.29rem;
            font-size: 0.07rem;
            border: 1px solid rgba(255, 255, 255, 0.5);
          }
        }
      }
    }

  }
  .flexbox-item-join {
    padding: 0 !important;
    h4 {
      padding: 0 0.14rem 0.24rem 0.24rem;

    }
  }
  .mobile-img {
      display: block;
      width: 100%;
      height: auto;
      img {
        max-width: 100%;
        height: auto;
      }
    }
::v-deep .el-select-dropdown__list {
  min-height: 100px;
}

}
</style>
