<!--
 * @Author: your name
 * @Date: 2021-11-25 14:08:02
 * @LastEditTime: 2022-01-26 09:55:46
 * @LastEditors: Please set LastEditors
 * @Description: 轮播图
 * @FilePath: \new-website-1123\src\views\programme\components\swiper.vue
-->
<template>
  <div class="programme-swiper">
    <p-title class="top-hide-page" v-if="title">
      {{ title.text }}
      <template v-slot:color>{{ title.color }}</template>
    </p-title>
    <div class="desc-div">
      <p class="desc-p">
        <slot name="desc"></slot>
      </p>
      <p class="desc-paragraph">
        <slot name="paragraph"></slot>
      </p>
    </div>
    <swiper
      class="right-div swiper"
      ref="swiper"
      :options="swiperOptions"
      @slideChange="slideChange"
    >
      <swiper-slide v-for="item in dataList" :key="item.key">
        <slot :row="item">
          <img
            class="data-img"
            :src="item.cover"
            alt=""
            :style="{
              'object-fit': fit,
            }"
          />
          <div class="filter-div"></div>
        </slot>
      </swiper-slide>
      <div class="swiper-pagination home-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import pTitle from "./title.vue";
export default {
  name: "",
  props: {
    title: {
      type: Object,
      // required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    fit: {
      type: String,
      default: "cover",
    },
  },
  components: {
    pTitle,
  },
  data() {
    let w = window.innerWidth > 800 ? 5 : 1;
    // let self = this;
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: true,// 自动播放
        loop: true,// 循环
        speed: 800, // 滑动速度
        mousewheel: false, // 鼠标滚轮控制
        slidesPerGroup: 1,// 每次滑动几个
        loopedSlides: w,// 循环模式下的前后各增加几个
        spaceBetween: 20,// 间隔
        preloadImages:true, // 预加载图片

      },
    };
  },
  created() {},
  methods: {
    slideChange() {
      this.$emit("change", this.$refs.swiper.$swiper.realIndex);
    }
  },
};
</script>

<style lang="scss" scoped>
.programme-swiper {
  padding-top: 100px;
  width: 100%;
  overflow: hidden;
  padding: 90px;
  .desc-p {
    width: 1200px;
    text-align: center;
    color: rgba($color: #fff, $alpha: 0.7);
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    margin: 50px auto;
  }

  .desc-paragraph {
    width: 1400px;
    text-align: center;
    color: rgba($color: #fff, $alpha: 0.7);
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    margin: 50px auto;
  }

  @media screen and (max-width: 1400px) {
    .desc-p {
      width: 1100px;
      font-size: 18px;
    }
    .desc-paragraph {
      width: 1200px;
      font-size: 20px;
    }
  }
}
.home-pagination {
  bottom: -30px;
  ::v-deep {
    .swiper-pagination-bullet {
      width: 24px;
      height: 3px;
      border-radius: 0;
      background-color: rgba($color: #fff, $alpha: 0.8);
      &.swiper-pagination-bullet-active {
        background-color: $color-active;
      }
    }
  }
}
.right-div {
  width: 100%;
}
.data-img {
  width: 100%;
  height: 100%;
  object-fit: cover; // 保持图片比例

}
.filter-div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #161616;
  opacity: 0.4;
  transition: opacity 0.3s;
}

::v-deep {
  .swiper-container {
    overflow: visible !important;
    width: 900px;
    height: 600px;
  }
  .swiper-container .swiper-wrapper .swiper-slide {
    width: 900px;
    position: relative;
  }
  .swiper-container .swiper-wrapper .swiper-slide-prev {
    height: 600px !important;
  }
  .swiper-container .swiper-wrapper .swiper-slide-prev img {
    height: 600px !important;
  }
  .swiper-container .swiper-wrapper .swiper-slide-next {
    height: 600px !important;
  }
  .swiper-container .swiper-wrapper .swiper-slide-next img {
    height: 600px !important;
  }
  .swiper-container .swiper-wrapper .swiper-slide-active {
    width: 600px;
    .filter-div {
      // background-color: rgba($color: #161616, $alpha: 0);
      opacity: 0;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .right-div {
    width: 86%;
  }
  .programme-swiper {
    padding: 0;
    padding-top: 60px;
    width: 100%;
    overflow: unset;
    .desc-p {
      width: 86%;
      font-size: 0.27rem;
      line-height: 0.44rem;
      margin: 0.27rem auto;
    }

    .desc-paragraph {
      width: 86%;
      font-size: 0.27rem;
      line-height: 0.44rem;
      margin: 0.27rem auto;
    }
  }

  ::v-deep {
    .swiper-container {
      overflow: visible !important;
      width: 86vw;
      height: 5.91rem;
    }
    .swiper-container .swiper-wrapper .swiper-slide {
      width: 86vw;
      position: relative;
    }
    .swiper-container .swiper-wrapper .swiper-slide-prev {
      height: 5.91rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-prev img {
      height: 5.91rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-next {
      height: 5.91rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-next img {
      height: 5.91rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-active {
      width: 5.91rem;
      .filter-div {
        // background-color: rgba($color: #161616, $alpha: 0);
        opacity: 0;
      }
    }
  }
}
</style>
