<!--
 * @Author: your name
 * @Date: 2021-11-25 13:45:34
 * @LastEditTime: 2021-12-23 16:43:16
 * @LastEditors: Please set LastEditors
 * @Description: 解决方案标题
 * @FilePath: \new-website-1123\src\views\programme\components\title.vue
-->
<template>
  <div class="programme-title">
    <p>
      <span>
        <slot></slot>
      </span>
      <span class="col-g">
        <slot name="color"></slot>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.programme-title {
  font-size: 54px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  width: 100%;
  p {
    display: flex;
    margin: 0;
    justify-content: center;
  }
}

@media screen and (max-width: $mobile-width) {
  .programme-title {
    font-size: 0.49rem;
    width: 100%;
  }
}
</style>
